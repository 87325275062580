import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import Layout from "../../components/Layout"
import Text from "../../components/Text"
import { Featured } from "../../components/utils"
import { formatDate } from "../../utils/formatDate"

const Index = ({ data }) => {
  const metaTags = {
    tags: data.post.seoMetaTags.tags.map(tag =>
      (tag.attributes &&
        tag.attributes.name &&
        tag.attributes.name.includes("description")) ||
      (tag.attributes &&
        tag.attributes.property &&
        tag.attributes.property.includes("description"))
        ? {
            ...tag,
            attributes: {
              ...tag.attributes,
              content:
                data.post.text.value.document.children[0].children[0].value,
            },
          }
        : tag
    ),
  }

  const pages = [
    {
      name: data.magazine.title,
      link: data.magazine.slug,
      locale: data.magazine.locale,
      current: false,
    },
    {
      name: data.post.category.name,
      link: `${data.magazine.slug}/${data.post.category.slug}`,
      locale: data.magazine.locale,
      current: false,
    },
  ]

  return (
    <Layout transparentHeader>
      <HelmetDatoCms
        htmlAttributes={{
          lang: data.post.locale,
        }}
        seo={metaTags}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://google.com/article",
            },
            headline: data.post.title,
            image: [data.post.image.fluid.src],
            datePublished: data.post.meta.createdAt,
            dateModified: data.post.meta.updatedAt,
            author: {
              "@type": "Person",
              name: data.post.author.name,
            },
            publisher: {
              "@type": "Organization",
              name: "iKeys Realty",
              logo: {
                "@type": "ImageObject",
                url: "https://ikeys-realty.com/ikeys-logo.png",
              },
            },
          })}
        </script>
        {data.post._allSlugLocales.map((version, index) => (
          <link rel="alternate" hreflang={version.locale} href={`https://ikeys-realty.com/${version.locale}/magazine/${version.value}`} key={index} />
        ))}
      </HelmetDatoCms>
      <div className="post">
        <Featured
          data={data.post}
          title={data.post.title}
          subtitle={
            <>
              {formatDate(data.post.date, data.post.locale)}
              <span className="mx-2">·</span>
              {data.post.category.name}
            </>
          }
          image={data.post.image.fluid}
          linkDisabled
        />
        <Text
          text={data.post.text}
          breadcrumbs={pages}
          current={data.post.title}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PostQuery($locale: String!, $slug: String!) {
    post: datoCmsPost(locale: { eq: $locale }, slug: { eq: $slug }) {
      ...Post
      ...PostContent
    }
    magazine: datoCmsMagazine(locale: { eq: $locale }) {
      ...Magazine
    }
  }
`

export default Index

import React from "react"
import { Link } from "gatsby"
import { StructuredText } from "react-datocms"
import { GatsbyImage } from "gatsby-plugin-image"

import { Video } from "./blocks"
import { linkResolver } from "../utils/linkResolver"
import { Breadcrumbs } from "./utils"

const Text = ({ text, breadcrumbs, current }) => (
  <>
    <div className="py-12 mx-auto bg-white md:py-24">
      {breadcrumbs && current && (
        <div className="mb-4 content">
          <Breadcrumbs pages={breadcrumbs} current={current} />
        </div>
      )}
      <div className="max-w-full prose prose-lg text-gray-500 content">
        <StructuredText
          data={text}
          renderLinkToRecord={({ record, children }) => {
            return <Link to={linkResolver(record)}>{children}</Link>
          }}
          renderBlock={({ record }) => {
            switch (record.model.apiKey) {
              case "video":
                return <Video data={record} />
              case "image":
                return (
                  <div className="container my-12 md:my-24">
                    <GatsbyImage
                      image={record.image.gatsbyImageData}
                      alt={record.title}
                    />
                  </div>
                )
              default:
                return null
            }
          }}
        />
      </div>
    </div>
  </>
)

export default Text
